import React, { useState } from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"

import CatalogLayout from "../../components/CatalogLayout"
import SEO from "../../components/seo"
import CatalogProductCell from "../../components/Product/CatalogProductCell"

const Home = ({ data }) => {
  let allProducts = data.allGoogleSpreadsheetSheet1.nodes

  allProducts = allProducts
    .sort((a, b) => (a.ordering > b.ordering ? 1 : -1))
    .filter((product) => product.stock == "yes" && product.deleted != "yes")

  let products = splitArrayIntoChunksOfLen(allProducts, 25)

  return (
    <CatalogLayout>
      <SEO
        title="Tick Tock Inc."
        description="Importer and Distributor of General Merchandise in the Greater New York City Area."
      />

      <div id="printPage">
        {products.map((subProducts, index) => (
          <div key={`page-${index}`}>
            {index == 23 ? (
              <header className={`pb-16 p-2 border-2 mt-2 mb-1`}>
                <h1 className="font-extrabold text-xl text-blue-600  tracking-tight">
                  Tick Tock Inc.
                </h1>

                <p className="text-sm font-bold tracking-tight">
                  240 Moonachie Ave. Suite 400,
                  <br /> Moonachie, NJ 07074
                </p>
              </header>
            ) : (
              <header
                className={`py-3 p-2 ${
                  index == 0 && "text-center"
                } mt-2 mb-1 border-2`}
              >
                <h1
                  className={`font-extrabold text-3xl tracking-tight text-blue-600`}
                >
                  Tick Tock Inc.
                </h1>
                <b>
                  Importers & Distributors of General Merchandise — Est. 1987
                </b>

                <p>
                  240 Moonachie Ave. Suite 400, Moonachie, NJ 07074 | Phone:
                  201-853-9200 | Fax: 201-853-9477
                </p>
                <p>Email: tiktok@att.net | Website: https://ticktockinc.com</p>
              </header>
            )}

            <div className="grid grid-cols-5">
              {subProducts.map((product, subIndex) => (
                <>
                  <CatalogProductCell
                    key={product.ordering}
                    product={product}
                    subIndex={subIndex}
                  />
                </>
              ))}
            </div>

            {index != 25 && (
              <div className="flex">
                <footer className="py-2 text-sm">
                  Call to place an order today! Free delivery within the New
                  Jersey and New York Area. Prices subject to change.
                </footer>
                <p className="py-2">
                  <b>{index + 1}</b>
                </p>
              </div>
            )}

            <div className="break" />
          </div>
        ))}
      </div>
    </CatalogLayout>
  )
}

function splitArrayIntoChunksOfLen(arr, len) {
  var chunks = [],
    i = 0,
    n = arr.length
  while (i < n) {
    chunks.push(arr.slice(i, (i += len)))
  }
  return chunks
}

export const query = graphql`
  query MainCatalogQuery {
    allGoogleSpreadsheetSheet1(sort: { fields: ordering }) {
      nodes {
        ordering
        category
        subcategory
        itemCode
        packing
        price
        description
        stock
        deleted
      }
    }
  }
`

export default Home
